<template>
  <section id="home" class="home-sec">
    <section class="header-menu">
      <section class="header-sec">
        <v-container class="h-100">
          <v-row class="h-100">
            <img src="@/assets/img/dots.svg" class="img-fluid dots-img" />
            <v-col md="6" cols="12" class="h-md-100">
              <div class="conOfheadTxt w-80 h-100 d-flex justify-center">
                <div class="mainHeadTxt">
                  Move On can help relieve your pain
                </div>
                <div class="subHeadTxt">
                  Due to back pain, arthritis, shoulder pain, wrist pain, ankle
                  pain, knee pain, muscle soreness, sprains and strains, aching
                  joints, and bruises
                </div>
                <div class="conOfheadBtns mt-4">
                  <v-btn
                    class="readBtn defaultBtn mr-2"
                    @click="$router.push({ name: 'about' })"
                  >
                    Read More
                  </v-btn>
                  <v-btn
                    class="contactBtn defaultBtn"
                    @click="scrollToBottom()"
                  >
                    Contact Us
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col md="6" cols="12" class="h-md-100">
              <img src="@/assets/img/wave.png" class="img-fluid waveImg" />

              <div class="w-100 h-100 d-flex justify-center align-center">
                <img src="@/assets/img/head-img-min.png" class="img-fluid" />
              </div>
              <img src="@/assets/img/ball-min.png" class="img-fluid ballImg" />
            </v-col>
          </v-row>
        </v-container>
      </section>
    </section>
    <section id="keepMoveOn" class="keepMoveSec keepMoveOn">
      <v-container class="h-100">
        <v-row class="h-100">
          <v-col md="6" cols="12" class="h-md-100">
            <div class="conOfheadTxt h-100 d-flex justify-center">
              <div class="titleTxt mb-2">Keep Moving with Move On</div>

              <img
                src="@/assets/img/move-cream-min.png"
                class="img-fluid d-md-none d-flex"
              />

              <div class="subTitleTxt">
                Keep Active and Eat Healthy to Improve Well-being and Feel Great

                <div class="mt-2">
                  Eating foods that are good for you and staying physically
                  active may help you reach and maintain a healthy weight and
                  improve how you feel.
                </div>
                <div class="mt-2">
                  You also may find that moving more and eating better could
                  help you keep up with the demands of your busy life and be
                  there for the people who depend on you.
                </div>
              </div>
              <div class="conOfheadBtns mt-4">
                <v-btn
                  class="readBtn defaultBtn mr-2"
                  @click="$router.push({ name: 'KeepMoving' })"
                >
                  Read More
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col md="6" cols="12" class="h-md-100 d-flex justify-center">
            <div class="w-75"></div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="stopMoveOn" class="stopMoveSec stopMoveOn py-4">
      <v-container class="h-100 d-flex justify-center align-center">
        <v-row class="h-75 justify-center ma-0">
          <v-col cols="12" class="text-center conOftitle">
            <div class="titleTxt mb-2">What's Stopping You From Moving On</div>
            <div class="bar"></div>
          </v-col>
          <v-col
            md="5"
            cols="12"
            class="starsBg d-flex justify-center align-center"
          >
            <img
              src="@/assets/img/Move On Cream2-min.png"
              class="img-fluid stopImg"
            />
          </v-col>
          <v-col md="8" cols="10" class="text-center">
            <div class="subTitleTxt">
              The fear of long-term analgesics’ side effects and tolerance
              <br />
              Treatment complexity and pill burden
              <br />
              Suffering from other co-morbidities
            </div>
            <div class="conOfheadBtns mt-4">
              <v-btn
                class="readBtn defaultBtn mr-2"
                @click="$router.push({ name: 'stoppingYou' })"
              >
                Read More
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section
      id="UnderstandingPain"
      class="understandMoveSec UnderstandingPain py-4"
    >
      <v-container class="h-100">
        <v-row class="h-100 justify-center ma-0">
          <v-col cols="12" class="text-center conOftitle">
            <div class="titleTxt mb-2">Our Products</div>
            <div class="bar"></div>
          </v-col>
          <v-col cols="12">
            <!--    <v-slide-group
              class="pa-4 w-100"
              active-class="success"
              show-arrows
            >
              <v-slide-item :key="index" v-for="(item, index) in slides">
                <div class="ma-5 conOfSlider">
                  <div class="d-flex align-center justify-center conOfslideImg">
                    <img :src="item.img" class="slideImg" />
                  </div>
                  <div class="slideTxt">{{ item.title }}</div>
                  <div class="slideSubTxt">{{ item.dec }}</div>
                  <div class="readYellow">Read More</div>
                </div>
              </v-slide-item>
            </v-slide-group> -->
            <VueSlickCarousel :arrows="true" v-bind="settings">
              <div
                class="ma-4 conOfProduct"
                :key="index"
                v-for="(item, index) in slides"
              >
                <div class="d-flex align-center justify-center conOfslideImg">
                  <img :src="item.img" :class="!item.cream ? 'slideImg' : ''" />
                </div>
                <div class="slideTxt">{{ item.title }}</div>
                <div class="slideSubTxt">{{ item.dec }}</div>
                <router-link to="/products" class="readYellow"
                  >Read More <span>&#8594;</span></router-link
                >
              </div>
            </VueSlickCarousel>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "Home",
  components: {
    VueSlickCarousel,
  },

  data() {
    return {
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      slides: [
        {
          img: require("@/assets/img/Move on freeze spray-min.png"),
          title: "Move On Freeze Spray",
          dec: "Freeze spray for athletes, fitness enthusiasts, and sporty people who require cooling effect to relieve muscle spasm/ cramps and aches, easy to carry and apply within seconds , provides a highly pressurized force of cooling menthol that quickly numbs the pain, low temperatures immediately relieve swelling and pain",
        },
        {
          img: require("@/assets/img/Move On Freez Gel-min.png"),
          title: "Move On Freeze Gel",
          cream: true,

          dec: "Gel for rapid relief with cooling effect targeting young people who are exposed to accidental injuries and muscle aches",
        },

        {
          img: require("@/assets/img/Move on Spray-min.png"),
          title: "Move On Spray",
          dec: "Spray for easy application for those who do not tolerate or do not like rubbing cream, it also provides instant relief, Sprays can be excellent for hard-to-reach spots like your back.",
        },
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          cream: true,
          title: "Move On Cream",
          dec: " Cream is for elderlies suffering from chronic pain and muscle ache.",
        },
        {
          img: require("@/assets/img/Move On Freeze Patch-min.png"),
          title: "Move On Freeze Patch",
          dec: "Relieves minor pain associated with Muscular pain,Back pain,Joint pain,Backache,Bruise and Sprain",
        },
        {
          img: require("@/assets/img/Move On Hot Patch-min.png"),
          title: "Move On Hot Patch",
          dec: "Relieves minor pain associated with Muscular aches ,Back pain,Arthritis,Strains,Bruises and Sprains",
        },
      ],
    };
  },

  mounted() {
    /* setTimeout(() => {
      var el = this.$el.getElementsByClassName(this.$route.query.id)[0];
      el.scrollIntoView({ behavior: "smooth" });
      console.log(this.$route.query.id);
    }); */
  },
  created() {},
  methods: {
    scrollToBottom() {
      window.scrollTo(
        0,
        document.body.scrollHeight || document.documentElement.scrollHeight
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_landing-page.scss";
</style>
